<template>
    <el-card class="record-card">
        <el-row>
            <el-col :span="6">
                <el-image class="img" fit="cover" :src="require('../../assets/images/record-course.png')"/>
            </el-col>
            <el-col :span="15">
                <div style="height: 80px;">
                    <div class="title">
                        {{ item.whUserStudyRecord.contentType ? item.whLive.name : item.whCourse.name }}
                    </div>
                    <div v-if="!item.whUserStudyRecord.contentType" class="module">
                        {{ item.whCourseModuleContent.name }}
                    </div>
                </div>
                <div style="display: flex; align-items: center;">
                    <el-tag type="info" size="small">
                        {{ item.whUserStudyRecord.contentType ? '直播课' : '录播课' }}
                    </el-tag>
                    <div class="tips">
                        {{ item.whUserStudyRecord.lastStudyTime }}
                    </div>
                </div>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
export default {
    name: "recordCard",
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        }
    }
}
</script>

<style scoped lang="scss">
.record-card {
    margin-bottom: 20px;
    cursor: pointer;

    .img {
        border-radius: 5px;
        height: 100px;
        width: 180px;
    }

    .title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .module {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .tips {
        color: #999;
        font-size: 15px;
        margin-left: 20px;
    }
}
</style>