<template>
    <div>
        <page-list ref="pageList"
                   :span="24"
                   :package-list="data"
                   :is-show-result-num="false"
                   @search="search"
                   @clickItem="clickItem"
                   style="margin-top: -20px"
        >
            <template v-slot="scope">
                <record-card :item="scope.item"/>
            </template>
        </page-list>
        <div class="tips" v-if="data.length >= 10">
            当前只展示最近20条听课记录
        </div>
    </div>
</template>

<script>
import PageList from "../components/pageList";
import RecordCard from "../components/recordCard";

export default {
    name: "record",
    components: {RecordCard, PageList},
    data() {
        return {
            data: []
        }
    },
    methods: {
        // 搜索数据
        search() {
            // 向后端发送请求数据
            this.$api.get('/auth/info/getMyStudyRecord').then(res => {
                this.data = res.data
            }).catch(err => {
                console.log(this.$route.meta.title + '数据获取失败', err)
            })
        },
        // 去内容页
        toDetail(item) {
            if (item.whUserStudyRecord.contentType === 0) {
                // 录播课程
                this.$router.push('/course/index/' + item.whCourse.id)
            } else if (item.whUserStudyRecord.contentType === 1) {
                // 直播课程
                this.$router.push('/live/index/' + item.whLive.id)
            }
        },
    }
}
</script>

<style scoped lang="scss">
.tips {
    margin-top: 100px;
    font-size: 15px;
    color: #999;
    text-align: center;
}
</style>